<template>
  <div>
    <a-modal :visible="visible" title="物料需求" :width="1400" :footer="null" @cancel="cancel">
      <a-space style="margin-bottom: 12px">
        <a-select v-model="materialStatus" placeholder="备料状态" allowClear style="width: 200px">
          <a-select-option value="completed">已完成备料</a-select-option>
          <a-select-option value="uncompleted">未完成备料</a-select-option>
        </a-select>
        <a-select v-model="expirationStatus" placeholder="超期状态" allowClear style="width: 200px">
          <a-select-option value="expired">已超期</a-select-option>
          <a-select-option value="unexpired">未超期</a-select-option>
        </a-select>

        <a-button-group>
          <a-button type="primary" @click="batchStockOut">批量领料</a-button>
          <a-button type="primary" @click="batchStockReturn">批量退料</a-button>
        </a-button-group>
      </a-space>

      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :dataSource="filterDataItems"
        :loading="dataLoading"
        :pagination="false"
        :rowClassName="getRowClassName"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <template slot="action" slot-scope="value, item">
          <TableAction :productionOrderItem="productionOrderItem" :goodsItem="item.goods_item" :bomItem="item" />
        </template>
      </a-table>
    </a-modal>

    <BatchStockOutModal
      v-model="stockOutModalVisible"
      :productionOrderItem="productionOrderItem"
      :materialItems="selectedRowItems"
    />
    <BatchStockReturnModal
      v-model="stockReturnModalVisible"
      :productionOrderItem="productionOrderItem"
      :materialItems="selectedRowItems"
    />
  </div>
</template>

<script>
import { productionOrderBOMList } from "@/api/production";
import NP from "number-precision";
import moment from "moment";

export default {
  components: {
    TableAction: () => import("./TableAction"),
    BatchStockOutModal: () => import("./BatchStockOutModal"),
    BatchStockReturnModal: () => import("./BatchStockReturnModal"),
  },
  props: ["visible", "productionOrderItem"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      dataLoading: false,
      dataItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "零件编号",
          dataIndex: "goods_number",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "零件名称",
          dataIndex: "goods_name",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "规格",
          dataIndex: "spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "图号",
          dataIndex: "drawing_number",
          customRender: (_, item) => item.goods_item.drawing_number,
        },
        {
          title: "需求数量",
          dataIndex: "quantity",
          customRender: (value) => value * this.productionOrderItem.total_quantity,
        },
        {
          title: "已分配库存",
          dataIndex: "allocated_quantity",
          customRender: (_, item) => {
            const demand_quantity = NP.times(item.quantity, this.productionOrderItem.total_quantity);
            const remain_quantity = NP.minus(demand_quantity, item.stock_out_quantity);
            return item.allocable_quantity > remain_quantity
              ? demand_quantity
              : NP.plus(item.allocable_quantity, item.stock_out_quantity);
          },
        },
        {
          title: "欠量",
          dataIndex: "deficit_quantity",
        },
        {
          title: "已领料数量",
          dataIndex: "stock_out_quantity",
        },
        {
          title: "库存数量",
          dataIndex: "inventory_quantity",
        },
        {
          title: "退料数量",
          dataIndex: "stock_return_quantity",
        },
        {
          title: "预计完成时间",
          dataIndex: "expected_completion_date",
        },
        {
          title: "分类",
          dataIndex: "category_name",
          customRender: (_, item) => item.goods_item.category_name,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      selectedRowKeys: [],
      selectedRowItems: [],

      stockOutModalVisible: false,
      stockReturnModalVisible: false,
      materialStatus: undefined,
      expirationStatus: undefined,
    };
  },
  computed: {
    filterDataItems() {
      const dataItems = [];
      for (const item of this.dataItems) {
        const requireQuantity = NP.times(item.quantity, this.productionOrderItem.total_quantity);

        if (this.materialStatus === "completed" && item.stock_out_quantity < requireQuantity) {
          continue;
        }

        if (this.materialStatus === "uncompleted" && item.stock_out_quantity >= requireQuantity) {
          continue;
        }

        if (this.expirationStatus === "expired") {
          if (!item.expected_completion_date || moment() < moment(item.expected_completion_date)) {
            continue;
          }
        }

        if (this.expirationStatus === "unexpired") {
          if (item.expected_completion_date && moment() >= moment(item.expected_completion_date)) {
            continue;
          }
        }

        dataItems.push(item);
      }
      return dataItems;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
    },
    onSelectChange(selectedRowKeys, selectedRowItems) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowItems = selectedRowItems;
    },
    batchStockOut() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      this.stockOutModalVisible = true;
    },
    batchStockReturn() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      this.stockReturnModalVisible = true;
    },
    getRowClassName(item) {
      const demand_quantity = NP.times(item.quantity, this.productionOrderItem.total_quantity);
      const remain_quantity = NP.minus(demand_quantity, item.stock_out_quantity);
      const deficit_quantity =
        item.allocable_quantity > remain_quantity ? 0 : NP.minus(remain_quantity, item.allocable_quantity);
      return deficit_quantity > 0 ? "warning-row" : "";
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.materialStatus = undefined;
        this.expirationStatus = undefined;

        this.dataLoading = true;
        productionOrderBOMList({ id: this.productionOrderItem.id })
          .then((data) => {
            const bomComponentItems = data["bom_component_items"];
            const stockOutDetailItems = data["stock_out_details"];
            const stockReturnDetailItems = data["stock_return_details"];
            for (const bomComponentItem of bomComponentItems) {
              bomComponentItem.stock_out_quantity = 0;
              bomComponentItem.stock_return_quantity = 0;

              if (this.productionOrderItem.start_time && bomComponentItem.goods_item.delivery_days) {
                bomComponentItem.expected_completion_date = moment(this.productionOrderItem.start_time)
                  .add(bomComponentItem.goods_item.delivery_days, "days")
                  .format("YYYY-MM-DD");
              }

              for (const stockOutDetailItem of stockOutDetailItems) {
                if (bomComponentItem.goods === stockOutDetailItem.goods) {
                  bomComponentItem.stock_out_quantity = NP.minus(
                    stockOutDetailItem.total_quantity,
                    stockOutDetailItem.remain_quantity
                  );
                }
              }

              for (const stockReturnDetailItem of stockReturnDetailItems) {
                if (bomComponentItem.goods === stockReturnDetailItem.goods) {
                  bomComponentItem.stock_return_quantity = NP.minus(
                    stockReturnDetailItem.total_quantity,
                    stockReturnDetailItem.remain_quantity
                  );
                }
              }

              const demand_quantity = NP.times(bomComponentItem.quantity, this.productionOrderItem.total_quantity);
              const remain_quantity = NP.minus(demand_quantity, bomComponentItem.stock_out_quantity);
              if (bomComponentItem.allocable_quantity > remain_quantity) {
                bomComponentItem.deficit_quantity = 0;
              } else {
                bomComponentItem.deficit_quantity = NP.minus(remain_quantity, bomComponentItem.allocable_quantity);
              }
            }

            this.dataItems = bomComponentItems;
          })
          .finally(() => (this.dataLoading = false));
      }
    },
  },
};
</script>

<style scoped></style>
